import { useState, useEffect, useRef } from "react";
import "../styles/terminal.css";

const commands = {
  help: (
    <p>
      Available commands: help, about, contacts, skills, experience, clear.
      <br></br>
      Or <a href="/">go back</a>.
    </p>
  ),
  about:
    "\nAbout me:\n- Experienced Software Engineer with a strong background in Informatics and Computer Science.\n- Skilled in addressing complex challenges within blockchain projects, contributing to effective solutions.\n- Experienced in designing and implementing microservices architectures to enhance scalability and flexibility within software systems.",
  contacts: (
    <p>
      <br></br>You can reach me with:<br></br>-{" "}
      <a
        href="https://t.me/Nikolay_Lebedinsky"
        rel="noreferrer"
        target={"_blank"}
      >
        Telegram
      </a>
      <br></br>-{" "}
      <a
        href="https://www.linkedin.com/in/nikolay-lebedinsky/"
        rel="noreferrer"
        target={"_blank"}
      >
        LinkedIn
      </a>
      <br></br>-{" "}
      <a href="https://github.com/joxerx" rel="noreferrer" target={"_blank"}>
        GitHub
      </a>
      <br></br>- <a href="mailto: nikolay@lebed.dev">Email</a>
    </p>
  ),
  skills:
    "\nLanguages:\n- Proficient: Python, TypeScript/JavaScript\n- Familiar: Golang, Rust, C#\n\nFrameworks: Django Rest Framework, Django Channels, React\n\nDatabases: PostgreSQL, Redis, Clickhouse\n\nMessaging: Redis Streams, NATS JetStream",
  experience:
    "\nSoftware Engineer, DevOps at Rock'n'Block | Mar 2023 – Present\n- Development of blockchain projects of various complexity levels from scratch\n- Support for existing projects\n- Working on marketplaces, decentralized exchanges, and business logic for apps\n- Security and DevOps consulting and management\n\nSoftware Engineer at St. Petersburg SMTU | Apr 2022 - Apr 2023\n- Telegram bots development\n- Development of plastic usage tracker for 3D printing\n- Linux servers management",
  clear: "clear",
};

export default function Terminal() {
  const [history, setHistory] = useState([
    {
      prompt: "➜",
      directory: "~",
      command: (
        <p>
          Type 'help' to see available commands. Or click <a href="/">here</a>{" "}
          to go back
        </p>
      ),
    },
  ]);
  const [input, setInput] = useState("");
  const [commandIndex, setCommandIndex] = useState(-1);
  const [commandHistory, setCommandHistory] = useState([]);

  const inputRef = useRef(null);
  const terminalRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  });

  useEffect(() => {
    if (terminalRef.current) {
      terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
    }
  }, [history]);

  const handleCommand = (command) => {
    let output;
    if (command === "clear") {
      setHistory([]);
      setCommandHistory((prev) => [...prev, command]);
      setCommandIndex(-1);
      return;
    }
    if (command.startsWith("echo ")) {
      output = command.slice(5);
    } else if (command.startsWith("cat ")) {
      const fileName = command.slice(4);
      output =
        commands[fileName] || `cat: ${fileName}: No such file or directory`;
    } else {
      output = commands[command] || "Command not found";
    }
    setHistory((prev) => [
      ...prev,
      { prompt: "➜", directory: "~", command },
      { command: output },
    ]);
    setCommandHistory((prev) => [...prev, command]);
    setCommandIndex(-1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim()) {
      handleCommand(input.trim());
      setInput("");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp") {
      e.preventDefault();
      if (
        commandHistory.length > 0 &&
        commandIndex < commandHistory.length - 1
      ) {
        const newIndex = commandIndex + 1;
        setCommandIndex(newIndex);
        setInput(commandHistory[commandHistory.length - 1 - newIndex]);
      }
    } else if (e.key === "ArrowDown") {
      e.preventDefault();
      if (commandIndex > 0) {
        const newIndex = commandIndex - 1;
        setCommandIndex(newIndex);
        setInput(commandHistory[commandHistory.length - 1 - newIndex]);
      } else if (commandIndex === 0) {
        setCommandIndex(-1);
        setInput("");
      }
    }
  };

  return (
    <div className="terminal-wrapper" onClick={() => inputRef.current.focus()}>
      <div className="terminal">
        <div className="terminal-header">
          <span className="terminal-button close"></span>
          <span className="terminal-button minimize"></span>
          <span className="terminal-button fullscreen"></span>
        </div>
        <div className="terminal-body" ref={terminalRef}>
          {history.map((entry, index) => (
            <div key={index} className="terminal-line">
              {entry.prompt && <span className="prompt">{entry.prompt}</span>}
              {entry.directory && (
                <span className="directory">{entry.directory}</span>
              )}
              <span className="command">{entry.command}</span>
            </div>
          ))}
          <form onSubmit={handleSubmit} className="terminal-input">
            <span className="prompt">➜</span>
            <span className="directory">~</span>
            <input
              className="input"
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={handleKeyDown}
              autoFocus
              ref={inputRef}
            />
          </form>
        </div>
      </div>
    </div>
  );
}
