import React from "react";

import {
  AiOutlineGithub,
  AiOutlineLinkedin,
  AiOutlineMail,
} from "react-icons/ai";
import { FaTelegramPlane } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

import profile from "../media/profile_pic.jpg";

import "../styles/home.css";

const Home = () => {
  const navigate = useNavigate();

  document.addEventListener("keydown", function (event) {
    if (event.key === "Enter") {
      navigate("/terminal");
      console.log("clicking");
    }
  });
  return (
    <div className="Home">
      <div className="Home-Name">
        <h1 className="glitch" data-text="Nikolay Lebedinsky">
          Nikolay Lebedinsky
        </h1>
      </div>

      <div className="Home-TitleIMG">
        <div className="Home-IMGFrame">
          <img src={profile} alt="Avatar" />
        </div>

        <p>Software Engineer</p>
      </div>
      <div className="Home-More">
        <h3>
          click <Link to={"/terminal"}> Enter</Link> for more information
        </h3>
      </div>

      <div className="Home-Links">
        <a href="https://github.com/joxerx" rel="noreferrer" target={"_blank"}>
          <AiOutlineGithub />
        </a>
        <a href="mailto: nikolay@lebed.dev">
          <AiOutlineMail />
        </a>
        <a
          href="https://www.linkedin.com/in/nikolay-lebedinsky/"
          rel="noreferrer"
          target={"_blank"}
        >
          <AiOutlineLinkedin />
        </a>
        <a
          href="https://t.me/Nikolay_Lebedinsky"
          rel="noreferrer"
          target={"_blank"}
        >
          <FaTelegramPlane />
        </a>
      </div>
    </div>
  );
};

export default Home;
